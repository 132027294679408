/* css/Terms.css */

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1.6;
    margin: 20px;
}

ol {
    margin-left: 20px;
    margin-bottom: 20px;
}

li {
    margin-bottom: 8px;
}

.consent {
    text-align: center;
    margin: 0 auto;
    max-width: 80%;
}
