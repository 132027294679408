h2 {
  font-size: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.trainer-container {
  margin-top: 300px;
  margin-bottom: 300px;
  margin-right: 10px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

article {
  flex-basis: calc(33.33% - 20px);
  margin-right: 20px;
  margin-bottom: 20px;
  display: none;
  position: absolute;
}

article:first-child {
  display: block;
}

.article-image {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.article-title {
  font-size: 20px;
  margin-top: 10px;
}

.article-text {
  font-size: 18px;
}

@media (max-width: 768px) {
  h2 {
    font-size: 24px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }

  .trainer-container {
    margin-top: 270px;
    margin-bottom: 250px;
  }

  article {
    flex-basis: calc(50% - 20px);
  }

  .article-title {
    font-size: 18px;
    margin-top: 8px;
  }
}

@media (max-width: 425px) {
  h2 {
    font-size: 24px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }

  .trainer-container {
    margin-top: 220px;
    margin-bottom: 200px;
  }

  article {
    flex-basis: 100%;
  }

  .article-title {
    font-size: 16px;
    margin-top: 6px;
  }
}