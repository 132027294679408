@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.wrapper .image {
    animation: fadeInLeft 4s ease-in-out;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.wrapper.reverse .image {
    animation: fadeInRight 4s ease-in-out;
}

.wrapper {
    width: 97%;
    background-color: #fafafa;
}

.wrapper .container {
    max-width: 90%;
    margin: 0px auto;
    padding: 80px 0px;
}

@media (min-width: 992px) {
    .wrapper .container .content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.wrapper .content-item {
    width: 95%;
}

@media (min-width: 992px) {
    .wrapper .content-item {
        width: 50%;
    }
}

.wrapper .image {
    display: block;
    max-width: 97%;
    margin: 0px auto;
}

.wrapper .text {
    padding: 70px;
}

.wrapper .text .heading {
    margin: 0px 0px 40px 0px;
    font-size: 26px;
    font-weight: normal;
    text-align: center;
}

.wrapper.reverse .content {
    flex-direction: row-reverse;
}

@media (max-width: 991px) {
    .wrapper .container .content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .wrapper .content-item {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 40px 0;
    }

    .wrapper .container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        max-width: 1200px;
    }

    .wrapper.reverse {
        flex-direction: row-reverse;
    }

    .wrapper .content-item {
        flex: 1;
        padding: 16px;
    }

    .wrapper .image {
        max-width: 100%;
    }

    .wrapper .text {
        max-width: 500px;
        margin: 0 auto;
        margin-left: 13px;
    }
}