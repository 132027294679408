h2 {
  font-size: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.trainer-container {
  margin-top: 300px;
  margin-bottom: 300px;
  margin-left: 10px;
  display: flex;
  justify-content: center; /* Center the contents horizontally */
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

article {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.article-image {
  width: 90%;
  max-width: 400px;
  height: auto;
}

.article-title {
  font-size: 20px;
  margin-top: 10px;
}

.article-text {
  font-size: 18px;
}


@media (max-width: 768px) {
  h2 {
    font-size: 24px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }

  .trainer-container {
    margin-top: 270px;
    margin-bottom: 250px;
  }

  article {
    flex-basis: calc(50% - 20px);
  }

  .article-title {
    font-size: 18px;
    margin-top: 8px;
  }
}

@media (max-width: 425px) {
  h2 {
    font-size: 24px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }

  .trainer-container {
    margin-top: 220px;
    margin-bottom: 200px;
  }

  article {
    flex-basis: 100%;
  }

  .article-title {
    font-size: 16px;
    margin-top: 6px;
  }
}
