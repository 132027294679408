@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.wrapper .image {
    animation: fadeInLeft 4s ease-in-out;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.wrapper.reverse .image {
    animation: fadeInRight 4s ease-in-out;
}

.wrapper {
    width: 97%;
    background-color: #fafafa;
}

.wrapper .container {
    max-width: 90%;
    margin: 0px auto;
    padding: 80px 0px;
}

@media (min-width: 992px) {
    .wrapper.reverse .content {
        flex-direction: row-reverse;
    }
}

@media (max-width: 991px) {
    .wrapper .container .content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .wrapper .content-item {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .wrapper {
        width: 90%;
        background-color: #fafafa;
    }

    .wrapper .text {
        padding: 30px;
    }

    .wrapper .text .heading {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .wrapper .text {
        max-width: 500px;
        margin: 0 auto;
        margin-left: 10px;
    }
}