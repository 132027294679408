/* Price.css */
h2 {
  font-size: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #3498db;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  table {
    font-size: 14px;
  }

  th,
  td {
    padding: 6px;
  }
}

@media screen and (max-width: 480px) {
  h2 {
    font-size: 24px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }

  h3 {
    font-size: 16px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }

  table {
    font-size: 12px;
  }

  th,
  td {
    padding: 4px;
  }
}