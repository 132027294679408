body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: rgb(0, 204, 255);
  color: #fff;
  padding: 30px;
}

.link-style {
  color: #ffffff;
  text-decoration: none;
}

@keyframes rollIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.rolling-header {
  animation: rollIn 1s forwards;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

section {
  margin: 20px 0;
  border: 1px solid #ccc;
}

h2 {
  margin: 0;
}

header ul li:hover {
  background-color: #eaeaea;
  border-radius: 50px;
}

header ul li a:hover {
  color: rgb(0, 0, 0);
}

.menu-button {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
  margin-left: auto;
  margin-right: -5px;
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
}

.footer {
  background-color: rgb(0, 204, 255);
  color: #fff;
  padding: 15px;
}

.footer nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer nav ul li {
  display: inline;
  margin-right: 5px;
}

.footer nav ul li a {
  text-decoration: none;
  color: #555555;
}

.footer .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .left-content {
  text-align: left;
  margin-bottom: 100px;
}

.footer .right-content {
  margin-top: 100px;
  text-align: right;
}

.footer .left-content nav ul li a {
  color: #ffffff;
}

.footer .left-content nav ul li a:hover {
  color: #555555;
}

.footer p {
  margin: 5px 0;
}

@media only screen and (max-width: 768px) {
  header {
    padding: 10px;
    flex-direction: column;
  }

  .rolling-header {
    animation: rollIn 1s forwards;
  }

  nav ul li {
    display: block;
    margin-bottom: 10px;
  }

  header,
  header ul li a {
    font-size: 16px;
  }

  .menu-button {
    display: block;
    position: absolute;
    top: 25px;
    right: 10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 40px;
    cursor: pointer;
    z-index: 1;
  }

  .menu-button.close {
    font-size: 30px;
    padding: 5px 10px;
    border: none;
    background: transparent;
    color: #fff;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    background-color: rgb(0, 204, 255);
    padding: 10px;
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: 1;
  }
  .nav-menu ul {
    margin: 0;
    padding: 0;
  }

  .nav-menu li {
    list-style: none;
    margin-bottom: 10px;
  }

  .nav-menu li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }

  .show {
    display: flex;
  }

  .footer .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer .left-content,
  .footer .right-content {
    margin: 0;
  }

  .footer nav ul li a {
    font-size: 14px;
  }

  .footer p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 480px) {
  header {
    padding: 5px;
  }

  .rolling-header {
    animation: rollIn 1s forwards;
    margin-left: 20px;
  }

  nav ul li {
    display: block;
    margin-bottom: 8px;
  }

  header,
  header ul li a {
    font-size: 14px;
  }

  .menu-button {
    display: block;
    position: absolute;
    top: 10px;
    right: 0px;
    background: none;
    border: none;
    color: #fff;
    font-size: 40px;
    cursor: pointer;
    z-index: 1;
  }

  .menu-button.close {
    font-size: 30px;
    padding: 5px 10px;
    border: none;
    background: transparent;
    color: #fff;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    background-color: rgb(0, 204, 255);
    padding: 10px;
    position: absolute;
    top: 50px;
    right: 5px;
    z-index: 1;
  }

  .nav-menu ul {
    margin: 0;
    padding: 0;
  }

  .nav-menu li {
    list-style: none;
    margin-bottom: 10px;
  }

  .nav-menu li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }

  .show {
    display: flex;
  }

  .footer .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer .left-content,
  .footer .right-content {
    margin: 0;
  }

  .footer nav ul li a {
    font-size: 12px;
  }

  .footer nav ul {
    width: 190px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .footer p {
    margin-top: 30px;
    font-size: 10px;
  }
}

