/* Sendrequest.css */
h2 {
    font-size: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #707070;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.button:hover {
    background-color: #b9b9b9;
}

.button:active {
    background-color: #7e7e7e;
}

@media screen and (max-width: 480px) {
    h2 {
        font-size: 24px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }
}