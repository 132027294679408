/* Contact.css */
h2 {
  font-size: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

form {
    max-width: 1000px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  input[type="text"],
  input[type="file"],
  textarea {
    padding: 10px;
    width: 100%;
    border: 2px solid #676767;
    border-radius: 8px;
    margin-bottom: 5px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    text-align: left;
  }

  .centered-link {
    margin-bottom: 30px;
    text-align: center;
  }
  
  button[type="submit"] {
    display: block;
    margin: 0 auto;
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    padding: 12px 24px;
    font-size: 24px;
    width: 30%;
    transition: all 0.3s ease;
    margin-bottom: 50px;
  }
  
  button[type="submit"]:hover {
    background-color: #a6a6a6;
  }
  
  @media (max-width: 425px) {
  h2 {
    font-size: 24px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
}